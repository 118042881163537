import $ from "jquery";

export default function launchIframe(clientId, scriptTag, hostedUrl, parentElementId) {

    const src = new URL(hostedUrl);
    src.searchParams.append('client-id', clientId);

    const iframe = $("<iframe/>")
       .attr({'src': src.href, 'frameBorder': 0})
       .css({"width": "100%", "height": "350px"});

    if (parentElementId) {
        iframe.appendTo($("#" + parentElementId));
    } else {
        iframe.insertAfter(scriptTag);
    }

    window.addEventListener("message", function(e) {
        if (e.origin === src.origin) {
            iframe.css("height", `${e.data.height}px`);

            //FIXME: Make this configurable - it's a nasty hack for our first iFrame deployment: Hamleys UK (LEGO)
            $('html, body').animate({
                scrollTop: iframe.offset().top - 200
            }, 0);
        }
    });
}