import {globalWrapperVariables} from "./globals";
import $ from "jquery";
import 'jquery-ui/ui/widgets/slider';
import styles from "../css/igs.css";
import splash from "./splash";
global['jQueryRippl'] = $.noConflict();

import igsHtml from './igs.html';
import angular from 'angular';
import launchIframe from "./iframe";

function parentDirectoryOf(path) {
     return path.substring(0, path.lastIndexOf('/'));
}

const version = process.env.NGC_PUBLIC_VERSION;
globalWrapperVariables.version = version;
console.log("Initializing IGS version " + version);

let bootStrapped = false;

global.com_conversity_scriptsDetected = false;

// bootstrap
$(() => {
    if (!global.com_conversity_scriptsDetected) {
        global.com_conversity_scriptsDetected = true;
        $('script[class="com_realtimerelevance"]').each(async (index, it) => {
            if (index > 0) throw new Error("Only one journey per page is supported.")
            await start($(it))
        });
    }
});

async function start(scriptTag) {

    const scriptTagSrc = scriptTag.attr('src');
    const clientId = scriptTag.attr('data-client-id');
    const parentElementId = scriptTag.attr('data-parent-element-id');

    const mainConfig = await loadMainConfig(scriptTagSrc);

    const clientConfig = clientId
        ? await loadClientConfig(mainConfig, clientId, scriptTagSrc)
        : JSON.parse(sessionStorage.getItem("com_realtimerelevance_client_data"));

    if (clientConfig['showDiagnostics']) {
        const diagnosticsDiv = $('<div/>').insertBefore(scriptTag);
        renderDiagnostics(clientConfig, diagnosticsDiv, clientId);
    }

    const hostedUrl = mainConfig['hostedUrl'];
    const hostedOrigin = new URL(hostedUrl).origin;
    const iFrame = clientConfig['iframe'] && window.location.origin !== hostedOrigin;

    if (iFrame) {
        launchIframe(clientId, scriptTag, hostedUrl, parentElementId);
    } else {
        await startEmbedded(clientId, mainConfig, clientConfig, scriptTag, parentElementId);
    }
}

async function startEmbedded(clientId, mainConfig, clientConfig, scriptTag, parentElementId) {
    loadCss(clientConfig['cssLocation']);
    if (clientConfig['customStyleCssLocation']) {
        loadCss(clientConfig['customStyleCssLocation']);
    }

    if (clientConfig['extraStyle']) {
        $('<style/>', { text: clientConfig['extraStyle'] }).insertBefore(scriptTag);
    }

    const ngcContainer = $(igsHtml).hide();
    if (parentElementId) {
        ngcContainer.appendTo($("#" + parentElementId));
    } else {
        ngcContainer.insertAfter(scriptTag);
    }

    const pageStyle = clientConfig['pageStyle'];

    if (pageStyle === "INLINE") ngcContainer.addClass('igsHideHeader');

    const pageStyleClass = getStyleClass(pageStyle);
    ngcContainer.addClass(pageStyleClass);

    if (pageStyle === "LIGHTBOX") {
        $('<div/>')
            .addClass(styles.overlay)
            .appendTo(ngcContainer);
    }

    await processLaunchType(clientId, mainConfig, clientConfig, ngcContainer, scriptTag);
}

async function loadMainConfig(scriptTagSrc) {

    const ngcPropertiesUri = parentDirectoryOf(scriptTagSrc) + '/ngc-properties.json';

    const properties = await $.get(ngcPropertiesUri).promise();
    console.log(properties);
    globalWrapperVariables.websocket_endpoint = properties.websocket_endpoint;
    return properties;
}

async function loadClientConfig(properties, clientId, scriptTagSrc) {
    const scriptUrlIsFullyQualified = (scriptTagSrc.match("^https?://") !== null);
    const embedEndpoint = scriptUrlIsFullyQualified ? new URL(scriptTagSrc).origin : "";
    const embedConfigUri = embedEndpoint + `${properties['embed_config_path']}/${clientId}.json`;

    console.log("Loading client properties from: " + embedConfigUri);
    return await $.get(embedConfigUri).promise();
}

function loadCss(cssUrl) {
    $('<link/>', {
        href: cssUrl,
        rel: 'stylesheet',
        type: 'text/css',
    }).appendTo($('head'));
}

function getStyleClass(pageStyle) {
    switch (pageStyle) {
        case "INLINE": return styles.inline
        case "FULL_SCREEN": return styles.fullscreen;
        case "LIGHTBOX": return styles.lightbox
        default: throw new Error(`Invalid page style [${pageStyle}]. Must be one of [INLINE, FULL_SCREEN, LIGHTBOX]`);
    }
}

function renderDiagnostics(clientConfig, diagnosticsDiv, clientId) {
    $('<h3/>', {text: `Diagnostic information`}).appendTo(diagnosticsDiv);
    $('<p/>', {text: `Conversity IGS Version: ${version}`}).appendTo(diagnosticsDiv);
    $('<p/>', {text: `Conversity jQuery version: ${$.fn.jquery}`}).appendTo(diagnosticsDiv);
    $('<p/>', {text: `Client ID: ${clientId}`}).appendTo(diagnosticsDiv);
    $('<pre/>', {style: 'white-space: pre-wrap;', text: JSON.stringify(clientConfig, null, 2)}).appendTo(diagnosticsDiv);
}

function startJourney() {
    document
    .getElementsByClassName("com_realtimerelevance")[0]
    .dispatchEvent(new Event("start"));
}

const isSessionStorageEnabled = () => {
  try {
    const key = `isSessionStorageEnabled`;
    window.sessionStorage.setItem(key, null);
    window.sessionStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

async function processLaunchType(clientId, mainConfig, clientConfig, ngcContainer, scriptTag) {

    scriptTag.on('start', async function() {
        if (!bootStrapped)  {
            await journeyBootStrap(clientId, mainConfig, clientConfig, ngcContainer);
        } else {
            ngcContainer.slideDown();
        }
    });

    switch (clientConfig['launchType']) {
        case "BUTTON":
            $('<button/>', {
                text: clientConfig['buttonText'],
                style: clientConfig['buttonStyle']
            }).on('click', async () => {
                if (!bootStrapped) await journeyBootStrap(clientId, mainConfig, clientConfig, ngcContainer);
            }).insertAfter(scriptTag);
            break;
        case "EVENT":
            scriptTag.on('start', async function() {
                if (!bootStrapped) await journeyBootStrap(clientId, mainConfig, clientConfig, ngcContainer);
            });
            break;
        case "AUTOMATIC":
            if ("LIGHTBOX" === clientConfig['pageStyle']) {
                let lightboxLaunchHtml = clientConfig['lightboxLaunchHtml'];
                if(lightboxLaunchHtml) {
                    $('<div/>').html(lightboxLaunchHtml).on('click', () => startJourney()).insertAfter(scriptTag);                    
                } else {
                    console.info("Lightbox launch html is empty. You may start journey programmatically by dispatching 'start' event on 'com_realtimerelevance' element");
                }
                if (isSessionStorageEnabled() && sessionStorage.getItem('startAgainClicked')) {
                    console.log("StartAgain clicked...")
                    sessionStorage.removeItem("startAgainClicked")
                    startJourney();
                }
            }
            else if (!bootStrapped) await journeyBootStrap(clientId, mainConfig, clientConfig, ngcContainer);
            break;
        default:
            throw new Error('Invalid launch type. Must be one of [BUTTON, EVENT, AUTOMATIC]');
    }
}

async function journeyBootStrap(clientId, mainConfig, clientConfig, ngcContainer) {

    const splashScreen = splash(clientConfig).insertAfter(ngcContainer);

    //throw Error();

    const queryParams = global['com_realtimerelevance']?.['extraArgs'] || {};
    queryParams.viewId = clientConfig['viewId'];
    if (clientId) queryParams["_deployment-id"] = clientId;

    // extract short-url-id if present
    const utmParams = parseUtm();
    console.log("UTM params = " + JSON.stringify(utmParams));
    Object.assign(queryParams, utmParams)

    const applyPlugins = async (plugins)=> {
        $.each(plugins, async (i, pluginName) => {
            const plugin = await import('./plugins/' + pluginName)
            plugin.apply(queryParams);
        });
    }

    const journeyEndpoint = clientConfig['journeyEndpoint'];
    let endpointUrl;

    if (journeyEndpoint) {
        // Legacy journeyEndpoint with params
        endpointUrl = new URL(journeyEndpoint);
        // Apply plugins
        await applyPlugins(endpointUrl.searchParams.getAll("_plugin"));
        endpointUrl.searchParams.delete('_plugin');
        endpointUrl.searchParams.forEach((value, key) => {
            queryParams[key] = value
            if (key === 'domainId') globalWrapperVariables['domainId'] = value;
        });
    } else {
        // New domainId + attributes + plugins
        const domainId = clientConfig['domainId'];
        globalWrapperVariables['domainId'] = domainId;
        endpointUrl = new URL(mainConfig.ngcBaseUrl);
        queryParams.domainId = domainId
        $.each(clientConfig.attributes, (k, v) => { queryParams[k] = v });

        await applyPlugins(clientConfig.plugins);
    }

    console.log("queryParams: " + JSON.stringify(queryParams))

    if (isSessionStorageEnabled() && sessionStorage.getItem('token')) {
        queryParams.token = sessionStorage.getItem('token');
        console.log("passing existing token: " + queryParams.token);
    }

    const html = await $.get(endpointUrl.origin + '/service/remote/get-process-view', queryParams).promise()
    scrapeGlobals(html, clientConfig);

    console.log("Globals: " + JSON.stringify(globalWrapperVariables))

    const processDiv = ngcContainer.find('div[view-process]');
    transferProcessAttributes(html, processDiv);

    const initCallback = (record, observer) => {
        observer.disconnect();
        splashScreen.remove();

        if("LIGHTBOX" === clientConfig['pageStyle']) {
            ngcContainer.slideDown();
        } else {
            ngcContainer.show();
        }     

        const showCloseButton = () => clientConfig['closeButton'];
        if (showCloseButton()) {
            const closeButton = $('<div/>', {html: '&times;'});
            closeButton.addClass(styles.closeButton);
            closeButton.show();
            closeButton.prependTo(ngcContainer);
            closeButton.on('click', () => ngcContainer.hide());
        }
    };
    const initObserver = new MutationObserver(initCallback);
    initObserver.observe(processDiv[0], { attributes: false, childList: true, subtree: false });

    if (window.parent !== window.self) {
        const resizer = function() {
            const divHeight = processDiv[0].scrollHeight;
            const body = $(document).find('body').first();
            const margins = body.outerHeight(true) - body.outerHeight();
            window.parent.postMessage({"height": divHeight + margins}, "*");
        }
        const resizeObserver = new ResizeObserver(resizer);
        resizeObserver.observe(processDiv[0]);
    }

    await import('./common/index').then(() => {
        angular.bootstrap(ngcContainer.parent(), ['frontendApp']);
        bootStrapped = true;
    });
}

/* We need the html to be a json object instead */
function scrapeGlobals(html, clientConfig) {
    const script = $('<div/>', { html: html }).find('script').text();
    const globalsFromScript = new Function(script + ' return globalWrapperVariables;').apply(null);

    $.each(globalsFromScript, (key, value) => {
         if (isSessionStorageEnabled() && key === 'token' && !sessionStorage.getItem('token')) {
             sessionStorage.setItem('token', value);
             console.log("new token: " + value);
         }
         globalWrapperVariables[key] = value
     });
    $.each(clientConfig, (key, value) => { globalWrapperVariables[key] = value });
}

/* We need the html to be a json object instead */
function transferProcessAttributes(fromHtml, toDiv) {
    const fromDiv = $('<div/>', { html: fromHtml }).find('div[view-process]');
    ['process-definition-id', 'view-id', 'interaction'].forEach(it => toDiv.attr(it, fromDiv.attr(it)));
}

function parseUtm() {
    const query = window.location.search
    const queryParams = new URLSearchParams(query);
    const utmParams = {}
    for (const [k, v] of queryParams) {
        if (k.startsWith("com.conversity.utm")) {
            utmParams[k.replace("com.conversity.", "")] = v
        }
    }
    return utmParams
}
