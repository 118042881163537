import splashStyles from "../css/splash.css";
import igsStyles from "../css/igs.css";

const showSplash = (deploymentConfig) => {

    const splash = jQueryRippl('<div/>');
    const splashContent = jQueryRippl('<div/>').addClass(splashStyles.splashCentered).appendTo(splash);

    const image = deploymentConfig['splashImageUrl'];
    if (image) {
        jQueryRippl('<div></div>')
            .append(jQueryRippl('<img/>', { src: image, height: '100px' }))
            .appendTo(splashContent);
    }

    jQueryRippl('<div></div>').addClass(splashStyles.dotFlashing).appendTo(splashContent);

    const message = deploymentConfig['splashMessage'];
    if (message) {
        jQueryRippl('<div></div>').append(jQueryRippl('<p/>', { html: message})).appendTo(splashContent);
    }

    const pageStyle = deploymentConfig['pageStyle'];
    switch (pageStyle) {
        case "INLINE":
            splash.addClass(igsStyles.inline).addClass(splashStyles.splashInline);
            break;
        case "LIGHTBOX":
            splash.addClass(igsStyles.lightbox);
            break;
        default:
            splash.addClass(igsStyles.fullscreen);
    }

    return splash;
}

export default showSplash;
